import axios from "axios";

function changeClassTile(event) {
  if (!event.target.closest(".description")) {
    const currentTile = event.currentTarget;
    const currentDescription = currentTile.querySelector(".description");
    currentDescription.classList.add("tile-selected");
  }
}

function pagination(amount) {
  const cards = document.querySelectorAll(".projects .tile");
  const PaginationContainer = document.querySelector(".projects .pagination");

  if (!cards.length || !PaginationContainer) {
    console.error("Required elements not found in the DOM.");
    return;
  }

  function showPage(pageNumber) {
    cards.forEach((card, index) => {
      if (index >= (pageNumber - 1) * amount && index < pageNumber * amount) {
        card.style.display = "flex";
      } else {
        card.style.display = "none";
      }
    });
  }

  function createPaginationLinks() {
    const pageCount = Math.ceil(cards.length / amount);
    PaginationContainer.innerHTML = "";
    for (let i = 1; i <= pageCount; i++) {
      const link = document.createElement("a");
      link.href = "#";
      link.textContent = i;
      link.classList.add("linkk");
      link.addEventListener("click", (e) => {
        e.preventDefault();
        const PaginationLinks = document.querySelectorAll(".projects .linkk");
        PaginationLinks.forEach((link) => {
          link.classList.remove("active");
        });
        link.classList.add("active");
        showPage(i);
      });
      PaginationContainer.appendChild(link);
    }
  }

  createPaginationLinks();
  showPage(1);

  let first_link = document.querySelector(".projects .linkk");
  if (first_link) {
    first_link.classList.add("active");
  }

  let h1 = document.querySelector(".scrollToThis");
  if (h1) {
    let paginationLinks = document.querySelectorAll("a.linkk");
    paginationLinks.forEach((link) => {
      link.addEventListener("click", function () {
        h1.scrollIntoView({ behavior: "smooth" });
      });
    });
  }
}

function openPrevScreen() {
  let prevScreen = document.querySelector(".prevScreenMobile");
  prevScreen.classList.remove("activePrevScreen");
  document.body.classList.add("no-scroll");
}

function closePrevScreen() {
  let prevScreenMobile = document.querySelector(".prevScreenMobile");
  prevScreenMobile.remove();

  document.body.classList.remove("no-scroll");

  let texts = document.querySelector(".layer .texts");
  texts.style.animationPlayState = "running";

  let textl = document.querySelectorAll(".textl");
  textl.forEach((item) => {
    item.style.animationPlayState = "running";
  });
}

function openLi(event) {
  const currentLi = event.currentTarget;
  currentLi.classList.toggle("open");
}

function showHeader() {
  let ul = document.querySelector("nav ul");
  ul.classList.add("activeUl");

  let overlay = document.querySelector(".overlay");
  overlay.classList.add("activeOverlay");

  function closeHeader() {
    ul.classList.remove("activeUl");
    overlay.classList.remove("activeOverlay");
  }

  let linksInNav = ul.querySelectorAll("a");

  linksInNav.forEach((link) => {
    link.addEventListener("click", closeHeader);
  });
  overlay.addEventListener("click", closeHeader);
}

function closeTile(event) {
  const currentButton = event.currentTarget;
  const currentTile = currentButton.closest(".tile");
  let currentDescription = currentTile.querySelector(".description");

  currentDescription.classList.remove("tile-selected");
  console.log(currentDescription);
}

function lazyLoading() {
  var lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));

  if ("IntersectionObserver" in window) {
    let lazyImageObserver = new IntersectionObserver(function (
      entries,
      observer
    ) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          let lazyImage = entry.target;
          lazyImage.src = lazyImage.dataset.src;
          lazyImage.classList.remove("lazy");
          lazyImageObserver.unobserve(lazyImage);
        }
      });
    });

    lazyImages.forEach(function (lazyImage) {
      lazyImageObserver.observe(lazyImage);
    });
  } else {
  }
}

function countProjects() {
  let projects = document.querySelectorAll(".tile");

  let countText = document.querySelector("span.projectCount");

  countText.innerHTML = projects.length;
}

function closePopUpContact() {
  let popUp = document.querySelector(".contactPopUp");
  let overlayFullScreen = document.querySelector(".overlayFullScreen");

  popUp.classList.remove("activePopUp");
  overlayFullScreen.remove();
}

function openContactPopUp() {
  let popUp = document.querySelector(".contactPopUp");
  popUp.classList.add("activePopUp");

  let closeButton = popUp.querySelector("button.close");

  let overlayFullScreen = document.createElement("div");
  overlayFullScreen.classList.add("overlayFullScreen");
  document.body.appendChild(overlayFullScreen);

  closeButton.addEventListener("click", closePopUpContact);
  overlayFullScreen.addEventListener("click", closePopUpContact);
}

function sendForm(event) {
  event.preventDefault();
  const TOKEN = "7150955030:AAFr62b08NbDpZgN5k70GlPwwO5rCEnU7HU";
  const CHAT_ID = "-1002057499075";
  const URI_API = `https://api.telegram.org/bot${TOKEN}/sendMessage`;

  let form = event.currentTarget.closest("form");
  let name = form.querySelector("input.name");
  let tel = form.querySelector("input.tel");

  let message = `<b>Новая заявка с Портфолио</b>`;
  message += `<b>Имя заказчика: </b>${name.value}`;
  message += `<b>Мессенджер заказчика: </b>${tel.value}`;

  axios.post(URI_API, {
    chat_id: CHAT_ID,
    parse_mode: "html",
    text: message,
  });

  let popUp = document.querySelector(".contactPopUp");
  let overlayFullScreen = document.querySelector(".overlayFullScreen");

  popUp.classList.remove("activePopUp");
  overlayFullScreen.remove();
}

export {
  changeClassTile,
  pagination,
  openPrevScreen,
  closePrevScreen,
  openLi,
  showHeader,
  closeTile,
  lazyLoading,
  countProjects,
  openContactPopUp,
  sendForm,
};
