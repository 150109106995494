import React, { useEffect, useState } from "react";
import "../css/reset.css";
import "../css/App.css";
import axios from "axios";
import {
  changeClassTile,
  pagination,
  openPrevScreen,
  closePrevScreen,
  openLi,
  showHeader,
  closeTile,
  lazyLoading,
  countProjects,
  openContactPopUp,
  sendForm,
} from "./components/functions.js";
import { type } from "@testing-library/user-event/dist/type/index.js";

/* =========================================================================== */

function FirstBlock() {
  return (
    <>
      <section class="first" id="home">
        <div class="container">
          <div class="layer layer1">
            <div class="texts">
              <div className="column">
                <div className="row">
                  <div className="circle"></div>
                  <div className="circle"></div>
                  <div className="circle"></div>
                </div>
                <div className="row-hr">
                  <hr className="yellow" />
                  <div className="block">
                    <div className="circle"></div>
                    <hr className="blue" />
                  </div>
                </div>
              </div>

              <h1 className="hero glitch layers">
                <span>Hello, I'm</span>
              </h1>
              <br />
              <div class="block">
                <div class="savitar hero glitch layers">
                  <span>Savitar</span>
                </div>
                <div class="d hero glitch layers">
                  <span>D</span>
                </div>
                <div class="f hero glitch layers">
                  <span>F</span>
                </div>
                <div class="x hero glitch layers">
                  <span>X</span>
                </div>
              </div>
              <div className="column-bottom">
                <hr className="left" />
                <div className="row">
                  <hr className="right" />
                  <hr className="blue" />
                </div>
              </div>
              <div class="anl">
                <div class="letters">
                  <div class="letterM">M</div>
                  <div class="textl">
                    odern<span class="hide">,</span>
                  </div>
                </div>
                <div class="letters">
                  <div class="letterL">L</div>
                  <div class="textl">ight</div>
                </div>
                <div class="letters">
                  <div class="letterAnd">&</div>
                </div>
                <div class="letters">
                  <div class="letterC">C</div>
                  <div class="textl">omfortable</div>
                </div>
              </div>
              <hr className="bottom" />

              <div class="bgtextunder"></div>
            </div>
          </div>
          <div className="absoluteElement triangle-right-top"></div>
          <div className="absoluteElement triangle-left-bottom"></div>
          <div className="absoluteElement trinagle-left-top"></div>
          <div className="absoluteElement triangle-right-bottom"></div>
          <div className="absoluteElement line-bottom"></div>
          <div className="absoluteElement line-top"></div>
        </div>
      </section>
    </>
  );
}

function SecondBlock() {
  const progressBars = [
    {
      name: "English B2+",
      className: "english",
      percent: 100,
    },
    {
      name: "HTML5",
      className: "html",
      percent: 95,
    },
    {
      name: "LESS",
      className: "less",
      percent: 95,
    },
    {
      name: "CSS3",
      className: "css",
      percent: 92,
    },
    {
      name: "Axios",
      className: "axios",
      percent: 83,
    },
    {
      name: "js",
      className: "js",
      percent: 82,
    },
    {
      name: "npm",
      className: "npm",
      percent: 80,
    },
    {
      name: "git",
      className: "git",
      percent: 75,
    },
    {
      name: "react.js",
      className: "react",
      percent: 74,
    },
    {
      name: "figma",
      className: "figma",
      percent: 64,
    },
    {
      name: "python",
      className: "python",
      percent: 56,
    },
    {
      name: "reast api",
      className: "restapi",
      percent: 50,
    },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const bar = entry.target.querySelector(".incl-bar");
          const percent = bar.getAttribute("data-percent");
          bar.style.width = percent + "%";
        }
      });
    });

    const elements = document.querySelectorAll(".progress-bar");
    elements.forEach((el) => observer.observe(el));

    return () => {
      elements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  return (
    <>
      <section className="second" id="second">
        <div className="overlay"></div>

        <header>
          <div className="lines">
            <button className="line" onClick={() => showHeader()}></button>
            <button className="line" onClick={() => showHeader()}></button>
            <button className="line" onClick={() => showHeader()}></button>
          </div>
          <nav className="nav">
            <div className="closebutton">
              <button className="close"></button>
            </div>
            <ul className="hideul">
              <li>
                <a className="linka" href="#home">
                  Home
                </a>
              </li>

              <li>
                <a className="linka" href="#second">
                  About me
                </a>
              </li>

              <li>
                <a className="linka" href="#third">
                  Projects
                </a>
              </li>

              <li>
                <a className="linka" href="#footer">
                  Contacts
                </a>
              </li>
            </ul>
          </nav>
        </header>

        <div className="title">
          <hr className="left" />
          <h1 className="hero glitch layers">
            <span>About me</span>
          </h1>
          <hr className="right" />
        </div>

        <div className="container">
          <div className="text">
            <h2>Innovation</h2>
            <h3>
              В мире, где технологии движутся вперед каждый день, я стараюсь
              оставаться актуальным. Моя любовь к новым фреймворкам и опыту с
              использованием передовых технологий помогает мне создавать
              веб-сайты, которые выглядят современно и предлагают новые решения
              для пользователей.
            </h3>
          </div>
          <div className="text">
            <h2>Precision</h2>
            <h3>
              Всё, что находится на экране, имеет для меня значение, я всегда
              сосредоточен на деталях. Любой проект отражает мою заботу о
              качестве: от чистоты кода до удобного пользовательского интерфейса
              – каждая деталь помогает создать впечатляющий, законченный внешний
              вид.
            </h3>
          </div>
          <div className="text">
            <h2>Problem-Solving</h2>
            <h3>
              Программирование интересно мне не только как долгое написание
              кода, но и как возможность решения трудных задач. Мне нравится
              разбираться со сложностями и выстраивать эффективные решения,
              которые дополняют пользовательский опыт и увеличивают общую
              производительность сайта.
            </h3>
          </div>
          <div className="text mobile-notmrg">
            <h2>Collaboration</h2>
            <h3>
              Фронтенд-разработка часто требует совместной работы с дизайнерами,
              бэкенд-разработчиками и менеджерами проектов. Я ценю командный дух
              и способен эффективно взаимодействовать с коллегами, чтобы
              совместно достигать поставленных целей и создавать продукты,
              которые превосходят ожидания клиентов.
            </h3>
          </div>
        </div>

        <div className="specs">
          <div className="myspecs">
            <h2>Hard Skills</h2>
            {progressBars.map((bar, index) => {
              return (
                <div className="progress-bar" key={index}>
                  <h3>{bar.name}</h3>
                  <div className="bar">
                    <div
                      className={bar.className + " incl-bar"}
                      data-percent={bar.percent}
                    >
                      <h2>{bar.percent}%</h2>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="column">
            <div className="apps">
              <h2>Инструменты, которые я использую при верстке сайтов</h2>
              <div className="img">
                <img src={process.env.PUBLIC_URL + "/img/svg/vs.svg"} alt="" />
                <img
                  src={process.env.PUBLIC_URL + "/img/svg/picsart.svg"}
                  alt=""
                />
                <img
                  src={process.env.PUBLIC_URL + "/img/svg/photoshop.svg"}
                  alt=""
                />
                <img
                  src={process.env.PUBLIC_URL + "/img/svg/midjorney.svg"}
                  alt=""
                />
                <img
                  src={process.env.PUBLIC_URL + "/img/svg/chatgpt.svg"}
                  alt=""
                />
                <img
                  src={process.env.PUBLIC_URL + "/img/svg/apache.svg"}
                  alt=""
                />
                <img
                  src={process.env.PUBLIC_URL + "/img/svg/github.svg"}
                  alt=""
                />
              </div>
            </div>
            <div className="resume">
              <hr className="left" />
              <div className="">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={process.env.PUBLIC_URL + "/resume/resume.pdf"}
                >
                  <p>Скачать мое резюме</p>
                </a>
              </div>
              <hr className="right" />
            </div>
          </div>
        </div>
        <div className="bottom-line"></div>
      </section>
    </>
  );
}

function ThirdBlock() {
  const [showProjects, setShowProjects] = useState([]);
  const [activeButtonType, setActiveButtonType] = useState(0);

  const tiles = [
    {
      image: "/img/projectsimg/kct.png",
      siteFunc: "Каталог высококачественных металлообрабатывающих инструментов",
      name: "CleanSpirit",
      price: "500$",
      class: "cleanspirit",
      deepType: 2,
      link: "https://kct.by/",
      ifCMS: 1,
      timeDev: "3.5 недели",
      stack: ["HTML", "CSS", "LESS", "JS", "Figma", "Axios", "PHP"],
      description: `Клинспирит - это многостраничный сайт, посвященный высококачественным металлообрабатывающим инструментам. Сайт включает в себя разделы: Главная, О компании, Каталог, Доставка и оплата и Контакты. `,
    },
    {
      image: "/img/projectsimg/aerosphere.png",
      siteFunc: "Климатическое оборудование",
      name: "Aerosphere",
      price: "800$",
      class: "aerosphere",
      deepType: 2,
      link: "https://belairgroup.bmv.by/",
      ifCMS: 1,
      timeDev: "3 недели",
      stack: ["HTML", "CSS", "LESS", "JS", "Figma", "Axios", "PHP"],
      description: `Aerosphere - это многостраничный сайт, посвященный климатическому оборудованию. Сайт включает в себя разделы: Главная, Услуги, Каталог, Наши проекты, Статьи, О компании и Контакты. На главной странице посетители могут ознакомиться с основными предложениями и новостями компании. `,
    },
    {
      image: "/img/projectsimg/nativa.png",
      siteFunc: "Продажа чайно-кофейного оборудования",
      name: "Nativa",
      price: "550$",
      deepType: 2,
      class: "nativa",
      link: "https://nativa.by/",
      ifCMS: 1,
      timeDev: "2 недели",
      stack: ["HTML", "CSS", "LESS", "JS", "Figma", "PHP"],
      description:
        "Nativa.by – дистрибьютор чайно-кофейной продукции, а также кофемашин в Республике Беларусь.",
    },

    {
      image: "/img/projectsimg/nixsol.png",
      siteFunc: "Предоставление услуг виртуальной помощи развития бизнеса",
      name: "NixxSol",
      deepType: 1,
      price: "350$",
      class: "nixxsol",
      link: "/projects/nixsol/build/index.html",
      ifCMS: 0,
      timeDev: "5 часов",
      stack: [
        "HTML",
        "CSS",
        "LESS",
        "JS",
        "React",
        "Figma",
        "Axios",
        "PHP",
        "Git",
      ],
      description: `Nixxsol - это динамичный сайт, предназначенный для предоставления услуг виртуальной помощи и ввода данных. Сайт служит платформой, которая предлагает пользователям профессиональные услуги по управлению данными и виртуальную поддержку, способствуя росту и успеху их бизнеса. Посетители сайта могут ознакомиться с отзывами довольных клиентов, получить консультацию специалистов и узнать о команде профессионалов, стоящих за этими услугами.`,
    },
    {
      image: "/img/projectsimg/marielt.png",
      siteFunc: "Агенство недвижимости",
      name: "Marielt",
      price: "600$",
      class: "marielt",
      deepType: 3,
      link: "https://marielt.by/",
      ifCMS: 0,
      timeDev: "3 недели",
      stack: [
        "HTML",
        "CSS",
        "LESS",
        "JS",
        "React",
        "Next",
        "Node.js",
        "Figma",
        "Axios",
        "Strapi",
      ],
      description: `Мариэлт - это многостраничный сайт, посвященный агенству недвижимости в Минске. Сайт включает в себя разделы: Главная, Квартиры, Дома и Участки, Коммерческая недвижимость, О нас, Вакансии
"Покупателю" `,
    },
    {
      image: "/img/projectsimg/codesolutions.png",
      siteFunc: "Мое Digital-агенство веб-разработки",
      name: "CodeSolutions",
      price: "Свой проект",
      class: "codesolutions",
      deepType: 3,
      link: "https://codesolutions.agency/",
      ifCMS: 0,
      timeDev: "3 суток",
      stack: ["HTML", "CSS", "LESS", "JS", "React", "Figma", "Axios"],
      description: `CodeSolutions - мой личный проект (агенство   веб-разработки), в котором я занимал позицию CEO и старшего frontend разработчки"`,
    },
    {
      image: "/img/projectsimg/hoobank.png",
      siteFunc: "Предоставление финансовых услуг",
      name: "HooBank",
      price: "150$",
      deepType: 1,
      class: "hoobank",
      link: "/projects/hoobank/index.html",
      ifCMS: 0,
      timeDev: "4 часа",
      stack: ["HTML", "CSS", "LESS", "JS", "React", "Figma"],
      description:
        "Hoooobank - это динамичный сайт, предназначенный для\n  предоставления финансовых услуг. Сайт служит лендингом, который\n  предлагает пользователям широкий спектр банковских услуг, включая\n  онлайн-банкинг, кредитные предложения, инвестиционные продукты и\n  инструменты для управления личными финансами. Посетители сайта\n  могут ознакомиться с разнообразными финансовыми планами, прочитать\n  отзывы довольных клиентов, получить консультацию специалистов и\n  узнать о команде профессионалов, стоящих за этими услугами.",
    },
    {
      image: "/img/projectsimg/codlinAgency.png",
      siteFunc: "Сайт агенства по разработке ПО CodlinAgency",
      name: "CodlinAgency",
      price: "120$",
      class: "codlin",
      deepType: 3,
      link: "/projects/CodlinAgency/index.html",
      ifCMS: 0,
      timeDev: "5 часов",
      stack: ["HTML", "CSS", "LESS", "JS", "React", "Дизайн придуман мной"],
      description:
        "CodlinAgency - это сайт агентства, специализирующегося на\n    разработке программного обеспечения. Сайт является лендингом\n    (одностраничный сайт), который представляет услуги агентства по\n    разработке программного обеспечения, портфолио выполненных\n    проектов, отзывы клиентов, контактную информацию и подробности о\n    команде разработчиков. Здесь вы найдете всё необходимое для\n    реализации ваших программных идей от концепции до запуска\n    продукта.",
    },
    {
      image: "/img/projectsimg/PulseProService.png",
      siteFunc: "Сайт автоматизированной торговли с помощью криптоботов",
      name: "Pulse Pro Service",
      price: "150$",
      class: "pulsePro",
      deepType: 3,
      link: "/projects/PulsePro_Service/main/index.html",
      ifCMS: 0,
      timeDev: "3 дня",
      stack: ["HTML", "CSS", "LESS", "JS", "Дизайн придуман мной"],
      description:
        "Puple Pro Service - это инновационный сайт, предназначенный для\n  автоматизации торговли на криптовалютном рынке с помощью\n  продвинутых торговых ботов. Сайт предлагает пользователям\n  многостраничное приложение, включающее в себя детальное описание\n  функционала ботов, истории успешных сделок, инструкции по\n  настройке и использованию, а также контактную информацию для\n  поддержки и консультаций. Этот ресурс станет надежным помощником\n  в мире криптовалютной торговли.",
    },
    {
      image: "/img/projectsimg/mcdonald.png",
      siteFunc: "Сайт раздачи NFT в честь 69-летия McDonald's",
      name: "McDonald's NFT",
      price: "80$",
      class: "mcDonald",
      deepType: 1,
      link: "/projects/MCdonaldNFT/index.html",
      ifCMS: 0,
      timeDev: "1 день",
      stack: ["HTML", "CSS", "LESS", "JS", "Git", "Figma"],
      description:
        "McDonald's NFT - это сайт для акции, посвященной 69-летию\n  McDonald’s с вечным шармом. Мы представляем нашу акцию “Classic\n  NFT Giveaway”, где мы отдаем дань уважения этому знаменитому\n  бренду самым новаторским способом. Пока McDonald’s готовится к\n  своему 69-летию, мы заранее делимся радостью с вами с помощью\n  эксклюзивной раздачи NFT!\n  Представьте себе: 15 апреля, когда вы задуваете свечи на торте\n  McDonald’s, в вашем цифровом кошельке будет ждать особый сюрприз\n  - ваш собственный кусочек истории McDonald’s, запечатленный в\n  NFT. Эти цифровые коллекционные предметы не просто токены; они -\n  ломтик ностальгии, дань уважения десятилетиям вкусных\n  воспоминаний и праздник вечного очарования McDonald’s.\n  Погрузитесь в коллекцию MC: каждый NFT - уникальное празднование\n  бесконечного наследия McDonald’s. Присоединяйтесь к нам в\n  памятовании 69 золотых лет с эксклюзивными цифровыми сувенирами!",
    },
    {
      image: "/img/projectsimg/global.png",
      siteFunc: "Сайт-визитка комании Глобал Техника",
      name: "Global Technik",
      price: "85$",
      class: "global",
      deepType: 3,
      link: "/projects/globalTechnick/index.html",
      ifCMS: 0,
      timeDev: "2 дня",
      stack: ["HTML", "CSS", "LESS", "JS", "Git"],
      description:
        "Global Technick - это сайт для компании, которая специализируется\n    на продаже запчастей, шин, колес, аккумуляторных батарей и\n    зарядных устройств, навесного оборудования для складской техники\n    китайских, японских и европейских производителей, а также\n    комплектующих для двигателей спецтехники. Сайт представляет\n    собой лендинг (одностраничный сайт), который содержит информацию\n    о компании.",
    },
    {
      image: "/img/projectsimg/housestroy.jpg",
      siteFunc: "Онлайн-магазин продажи строительных и ремонтных услуг",
      name: "HouseStroy",
      price: "600$",
      class: "housestroy",
      deepType: 2,
      link: "https://house-stroy.by/",
      ifCMS: 1,
      timeDev: "3 недели",
      stack: ["HTML", "CSS", "LESS", "JS", "React", "Git", "Axios", "Figma"],
      description:
        "HouseStroy - это веб-сайт строительной компании,\n    специализирующейся на строительстве и ремонте жилых и\n    коммерческих объектов. На сайте представлена подробная\n    информация о услугах, портфолио выполненных проектов, отзывы\n    клиентов, контактная информация.",
    },
    {
      image: "/img/projectsimg/aromastroy.jpg",
      siteFunc: "Онлайн-магазин продажи строительных услуг",
      name: "AromaStroy",
      price: "800$",
      class: "aromastroy",
      deepType: 2,
      link: "https://aroma-stroy.by/",
      ifCMS: 1,
      timeDev: "3.5 недели",
      stack: ["HTML", "CSS", "LESS", "JS", "React", "Git", "Axios", "Figma"],
      description:
        "AromaStroy - это сайт компании, которая занимается\n    строительством и ремонтом жилых и коммерческих помещений. Сайт\n    представляет собой многостраничное приложение, которое\n    демонстрирует услуги, портфолио, отзывы, контакты, блог и другую\n    информацию о компании.",
    },
    {
      image: "/img/projectsimg/kormvetagro.png",
      siteFunc: "Информационный сайт компании KormVetAgro",
      name: "KormVetArgo",
      price: "150$",
      class: "kormvetagro",
      deepType: 3,

      link: "https://kormvetagro.by/",
      ifCMS: 1,
      timeDev: "2 дня",
      stack: ["HTML", "CSS", "LESS", "JS", "React", "Git", "Axios", "Figma"],
      description:
        "KormVetAgro - это сайт для компании, которая занимается оптовой\n    торговлей ветеринарными препаратами, кормами и кормовыми\n    добавками. Сайт представляет собой многостраничное приложение,\n    которое содержит информацию о компании, новости, акции,\n    партнеров и контакты.",
    },
    {
      image: "/img/projectsimg/moskowreg.png",
      siteFunc: "Информационный сайт услуг компании MoskowReg",
      name: "MOSCOWREG",
      price: "100$",
      class: "moscowreg",
      deepType: 1,
      link: "/projects/moskowreg/index.html",
      ifCMS: 0,
      timeDev: "1 день",
      stack: ["HTML", "CSS", "LESS", "JS", "Git", "Axios", "Figma"],
      description:
        " MOSCOWREG - это сайт для компании, которая предоставляет\n      коммерческую помощь в оказании услуг в ГИБДД. Сайт представляет\n      собой лендинг (одностраничный сайт), который содержит информацию\n      о компании, услугах, ценах, отзывах и контактах.",
    },
    {
      image: "/img/projectsimg/cms.png",
      siteFunc: "Сайт для рассчета матрицы судьбы",
      name: "FateGrid",
      price: "110$",
      class: "fategrid",
      deepType: 1,
      link: "/projects/FateGrid/index.html",
      ifCMS: 0,
      timeDev: "3 дня",
      stack: ["HTML", "CSS", "LESS", "JS", "Git"],
      description:
        "FateGrid - это сайт, предназначенный для использования\n    калькулятора матрицы судьбы. Сайт представляет собой инструмент,\n    который позволяет пользователям ввести свои данные и получить\n    индивидуальный анализ своей судьбы. С помощью уникального\n    алгоритма расчета, FateGrid поможет вам понять свои возможности,\n    потенциал и направление жизни.",
    },
    {
      image: "/img/projectsimg/SmartFly.png",
      siteFunc: "Сайт компании SmartFly для продажи дронов",
      name: "SmartFly",
      price: "80$",
      deepType: 2,
      class: "smartfly",
      link: "/projects/SmartFLY/index.html",
      ifCMS: 0,
      timeDev: "1.5 дня",
      stack: ["HTML", "CSS", "LESS", "JS"],
      description:
        "SmartFly - это сайт компании, специализирующейся на разработке и\n    продаже дронов. Сайт представляет собой платформу, где вы можете\n    ознакомиться с широким ассортиментом дронов для различных целей:\n    от развлекательных до профессиональных. Кроме того, на сайте\n    SmartFly вы найдете информацию о технических характеристиках\n    дронов, возможностях применения и контактную информацию для\n    консультации и заказа.",
    },
    {
      image: "/img/projectsimg/StayInn.png",
      siteFunc: "Сайт отеля StayInn для бронирования номеров",
      name: "StayINN",
      price: "75$",
      class: "stayinn",
      deepType: 3,
      link: "/projects/StayInn/index.html",
      ifCMS: 0,
      timeDev: "1.5 дня",
      stack: ["HTML", "CSS", "LESS", "JS", "Git", "Figma"],
      description:
        "StayInn - это сайт отеля, где каждый гость может насладиться\n     комфортом и уютом. Просторные номера предлагают идеальное\n     сочетание роскоши и удобств, ресторан порадует вас изысканными\n     блюдами, а спа-центр поможет вам расслабиться и насладиться\n     отдыхом. Отличное расположение и высокий уровень обслуживания\n     гарантируют, что ваше пребывание в отеле будет незабываемым.\n     Забудьте о повседневных заботах и насладитесь идеальным отдыхом\n     в StayInn.",
    },
    {
      image: "/img/projectsimg/LostMine.png",
      siteFunc: "Сайт майнкрафт-сервера LostMine для покупки доната",
      name: "LostMine",
      price: "100$",
      class: "lostmine",
      deepType: 2,
      link: "/projects/LostMine/main/index.html",
      ifCMS: 0,
      timeDev: "2 дня",
      stack: ["HTML", "CSS", "LESS", "JS", "Figma"],
      description:
        " LostMine - это увлекательный майнкрафт-сервер, где вы можете\n      погрузиться в захватывающий игровой мир и насладиться уникальным\n      игровым опытом. На нашем сервере вы найдете разнообразные\n      возможности для игры, интересные квесты, увлекательные события и\n      возможность приобретения донат-привилегий для улучшения игрового\n      процесса.",
    },
    {
      image: "/img/projectsimg/CloudFlex.png",
      siteFunc: "Сайт аренды облачного хостинг CloudFlex",
      name: "CloudFlex",
      price: "90$",
      class: "cloudflex",
      deepType: 3,
      link: "/projects/CloudFlex/index.html",
      ifCMS: 0,
      timeDev: "2 дня",
      stack: ["HTML", "CSS", "LESS", "JS", "Axios", "Figma"],
      description:
        "CloudFlex - это инновационный облачный хостинг, предлагающий\n      надежные и высокопроизводительные решения для вашего\n      онлайн-присутствия. Наша платформа обеспечивает мгновенный\n      доступ к гибким облачным ресурсам, высокую скорость работы и\n      безопасное хранение данных. С CloudFlex вы получаете возможность\n      масштабировать свой бизнес, обеспечивая надежную основу для\n      вашего виртуального пространства.",
    },
    {
      image: "/img/projectsimg/AutoSpa.png",
      siteFunc: "Сайт автомойки AutoSPA с ценами на услуги",
      name: "AutoSpa",
      price: "60$",
      class: "autospa",
      deepType: 1,
      link: "/projects/AutoSPA/index.html",
      ifCMS: 0,
      timeDev: "1 день",
      stack: ["HTML", "CSS", "LESS", "JS", "Axios"],
      description:
        " AutoSPA - это современная автомойка, где ваш автомобиль получит\n      роскошный уход и блеск, как после посещения спа-центра. Наши\n      профессионалы используют высококачественные средства и\n      инновационное оборудование, чтобы обеспечить вашему\n      транспортному средству идеальную чистоту и блеск.",
    },
    {
      image: "/img/projectsimg/ReelsBuilder.png",
      siteFunc: "Сайт генерации видео для рилсов Instagram",
      name: "ReelsBuilder",
      price: "30$",
      class: "reelsbuilder",
      deepType: 3,
      link: "/projects/ReelsBuilder/templates/index.html",
      ifCMS: 0,
      timeDev: "1 день",
      stack: ["HTML", "CSS", "LESS", "JS", "Axios"],
      description:
        "ReelsBuilder - это инновационная платформа для создания\n    увлекательных видеороликов. Наш интуитивно понятный редактор\n    поможет вам легко собрать качественное видео, используя\n    разнообразные инструменты и эффекты. С ReelsBuilder вы сможете\n    выразить свою креативность и создать видеоролики, которые\n    захватят внимание аудитории.",
    },
    {
      image: "/img/projectsimg/denis.png",
      siteFunc: "Личный лендинг Дениса Некрасова",
      name: "Denis",
      price: "80$",
      deepType: 1,
      class: "reelsbuilder",
      link: "https://denisnekrasov.ru/",
      timeDev: "1 день",
      stack: ["HTML", "CSS", "LESS", "JS", "Axios", "Figma"],
      description: "",
    },
  ];

  function CreateTile() {
    return (
      <div className="container">
        {showProjects.map((tile, index) => (
          <div
            className="tile"
            key={index}
            style={{
              display: "flex",
            }}
            onClick={(event) => changeClassTile(event)}
          >
            <div className="prevScreen">
              <img
                className="lazy"
                data-src={process.env.PUBLIC_URL + tile.image}
                alt=""
              />
              <div className="bottom">
                <h2 className="bold orange">{tile.name}</h2>
                <div className="row">
                  <p>Нажмите на плитку</p>
                </div>
              </div>
            </div>
            <div className="description">
              <div className="row">
                <button onClick={(event) => closeTile(event)}>
                  <img
                    src={process.env.PUBLIC_URL + "/img/arrow-left.svg"}
                    alt=""
                  />
                </button>
              </div>
              <div className="rowFlex">
                <p>Ссылка на проект</p>
                <a
                  className={tile.class}
                  target="_blank"
                  rel="noreferrer"
                  href={process.env.PUBLIC_URL + tile.link}
                >
                  {tile.name}
                </a>
              </div>
              <h3>Технические данные проекта</h3>
              <ul>
                <li>
                  Время разработки:
                  <span className="regular"> {tile.timeDev}</span>
                </li>
                <li className="closed" onClick={(event) => openLi(event)}>
                  <div className="row">
                    Стек, использованный при создании:
                    <svg
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path d="M4 6H11L7.5 10.5L4 6Z" fill="#000000"></path>
                      </g>
                    </svg>
                  </div>
                  <ul>
                    {tile.stack.map((item, itemIndex) => {
                      return (
                        <li key={itemIndex}>
                          <span className="bold">{item}</span>
                        </li>
                      );
                    })}
                  </ul>
                </li>
                <li>
                  Бюджет проекта:
                  <span className="regular"> {tile.price}</span>
                </li>
                {tile.ifCMS === 1 && (
                  <li key={index}>
                    Использованная CMS (CRM):
                    <span className="regular"> MODX</span>
                  </li>
                )}
                <li>
                  Основные функции:
                  <span className="regular"> {tile.siteFunc}</span>
                </li>
              </ul>
              <h3>Описание проекта</h3>
              <p className="mainText">{tile.description}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }

  useEffect(() => {
    const filteredProjects = tiles.filter(
      (tile) => activeButtonType === 0 || tile.deepType === activeButtonType
    );
    setShowProjects(filteredProjects);
    window.innerWidth > 768 ? pagination(6) : pagination(3);

    lazyLoading();
  }, [activeButtonType]);

  useEffect(() => {
    window.innerWidth > 768 ? pagination(6) : pagination(3);
    countProjects();
    lazyLoading();
  }, [showProjects]);

  function handleChangeProjectType(index) {
    setActiveButtonType(index);
  }

  return (
    <section class="third" id="third">
      <div class="title">
        <hr className="left" />
        <h1 className="hero scrollToThis layers glitch">
          <span>
            projects (<span class="projectCount"></span>)
          </span>
        </h1>
        <hr className="right" />

        <p>
          Здесь вы можете просмотреть мои проекты, на публикацию которых
          заказчики / работодатели дали разрешение
        </p>
      </div>

      <div className="container-buttons">
        <button
          className={activeButtonType === 0 ? "active" : ""}
          onClick={() => handleChangeProjectType(0)}
        >
          Все
        </button>
        <button
          className={activeButtonType === 1 ? "active" : ""}
          onClick={(event) => handleChangeProjectType(1)}
        >
          Лендинг
        </button>
        <button
          className={activeButtonType === 2 ? "active" : ""}
          onClick={(event) => handleChangeProjectType(2)}
        >
          Интернет-магазин
        </button>
        <button
          className={activeButtonType === 3 ? "active" : ""}
          onClick={(event) => handleChangeProjectType(3)}
        >
          Корпоративный сайт
        </button>

        <div
          className="activeLayer"
          style={
            window.innerWidth > 768
              ? { left: `${activeButtonType * 25}%` }
              : { top: `${activeButtonType * 25}%` }
          }
        ></div>
      </div>

      <div class="projects">
        <CreateTile />;<div className="pagination"></div>
      </div>
    </section>
  );
}

function Footer() {
  return (
    <>
      <footer id="footer">
        <div class="contacts">
          <ul>
            <li>
              <a href="https://t.me/savitardfx">telegram</a>
            </li>
            <li>
              <a href="https://github.com/SavitarDFX?ysclid=lmxwzmoydp895224241">
                github
              </a>
            </li>
            <li>
              <a href="mailto:savitardfx@gmail.com">email</a>
            </li>
            <li>
              <button onClick={() => openContactPopUp()}>contact me</button>
            </li>
          </ul>
        </div>
        <div class="copyright">
          <h3>©️ SavitarDFX 2021-2024</h3>
        </div>
      </footer>
      <div className="contactPopUp">
        <div className="container">
          <div className="rowEnd">
            <button className="close">
              <img src={process.env.PUBLIC_URL + "/img/close.svg"} alt="" />
            </button>
          </div>

          <h2>Contact me</h2>

          <form className="contactMe">
            <input type="text" className="name" placeholder="Ваше имя" />
            <input
              type="text"
              className="tel"
              placeholder="Ваш мессенджер / номер телефона"
            />
            <button onClick={(event) => sendForm(event)}>
              <p>Отправить</p>
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

function PortfolioPart() {
  useEffect(() => {
    lazyLoading();
    countProjects();
    pagination(6);
    if (window.innerWidth <= 768) {
      pagination(3);
    }
  }, []);

  return (
    <>
      <FirstBlock />
      <SecondBlock />
      <ThirdBlock />
      <Footer />
    </>
  );
}

export default PortfolioPart;
